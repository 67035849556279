// extracted by mini-css-extract-plugin
export var address = "agence-module--address--f72a5";
export var bulletPoint = "agence-module--bullet-point--e211b";
export var bulletPointClosed = "agence-module--bullet-point-closed--917a1";
export var closed = "agence-module--closed--075ba";
export var hourContainer = "agence-module--hour-container--b77ec";
export var openingTime = "agence-module--opening-time--1766e";
export var sectionContentActions = "agence-module--section-content-actions--291b3";
export var sectionContentSubtitle = "agence-module--section-content-subtitle--e6afc";
export var sectionNews = "agence-module--section-news--80707";
export var titre = "agence-module--titre--ee20e";