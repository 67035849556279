import * as React from "react";
import SectionLeftRightComponent from "components/section-left-right/section-left-right.component";
import BreadcrumbComponent from "../../components/breadcrumb/breadcrumb.component";
import iconPhone from "images/icon-phone.svg";
import { useLocation } from "@reach/router";
import CmsButtonComponent from "../../components/cms-button/cms-button.component";
import {
  titre,
  sectionContentActions,
  sectionContentSubtitle,
  hourContainer,
  openingTime,
  bulletPoint,
  closed,
  bulletPointClosed,
  address
} from "./agence.module.scss";
import { graphql } from "gatsby";
import { buildSections } from "../../services/sections";
import { formatPhone, formatTime } from "../../utils/string-formatters";
import { useEffect, useState } from "react";
import { Seo } from "../../components/seo/seo";
import { Helmet } from "react-helmet";
import { isUserConsent} from "../../components/cookie-banner/cookie-banner.component";
import {computeAgencyOpen} from "../../services/agency.service";

const Agence = ({ data, pageContext }) => {
  const articles = data.cms.articles.data;
  const pageData = data.cms.pages.data[0].attributes;
  const sections = pageData.sections;
  const agency = data.cms.agence.data.attributes;
  const agencyDepartment = agency.departement.data?.attributes || {};
  const testimonial = data.cms.temoignages.data.length > 0 ? data.cms.temoignages.data[0].attributes : null;
  const parametre = data.cms.parametre.data.attributes;
  const breadcrumbPath = [
    { title: 'Localiser une agence', url: '/' },
    { title: agencyDepartment.region, url: '/' + agencyDepartment.slugRegion },
    { title: agencyDepartment.nom, url: '/' + agencyDepartment.slugRegion + '/' + agencyDepartment.slugDepartement },
    { title: agency.nom }
  ];
  const storeLocator = data.cms.storeLocator.data.attributes;

  const [isClosed, setIsClosed] = React.useState(false);
  const [openWhen, setOpenWhen] = React.useState("");
  const [openHour, setOpenHour] = React.useState("");

  useEffect(() => {
    let url = window.location.href.split("#");
    let target = url[url.length - 1].toLowerCase();
    let element = document.getElementById(target);
    element && element.scrollIntoView({ behavior: "smooth", block: "start"});
  }, []);
  
  React.useEffect( () => {
      computeAgencyOpenState();
      const intervalId = setInterval(computeAgencyOpenState, 10000);
      return () => clearInterval(intervalId);
  }, []);

  const computeAgencyOpenState = () => {
      const o = computeAgencyOpen(storeLocator);
      setIsClosed(o.isClosed);
      setOpenWhen(o.openWhen);
      setOpenHour(o.openHour);
  }

  const [search, setSearch] = useState('');
  
  const location = useLocation();
  useEffect( () => {
      if(location.state && location.state.selectedSearch) {
          setSearch(location.state.selectedSearch);
      }
  }, []);

  let agenceImage = agency.image.data ? agency.image.data.attributes.url : '';
  let logo = parametre.logo.data ? parametre.logo.data.attributes.url : '';

  agenceImage = agenceImage.replace('/upload/','/upload/c_scale%2Cw_512/').replace(/\.[^.]+$/,'.png');
  logo = logo.replace('/upload/','/upload/c_scale%2Cw_512/').replace(/\.[^.]+$/,'.png');

  return (
    <div className="container-fluid">
      <Seo pageContext={pageContext} pageData={pageData} />
        <Helmet>
            <script type="application/ld+json">
                {`
                {
                  "@context": "https://schema.org",
                  "@type": "ProfessionalService",
                  ${agenceImage !== '' ? `"image": ["${agenceImage}"],`:''}
                  "name": "Paritel `+agency.nom+`",
                  "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "${agency.adresse}",
                    "addressLocality": "",
                    "addressRegion": "",
                    "postalCode": "",
                    "addressCountry": "France"
                  },
                  "telephone": "${agency.telephoneReel}",
                  "openingHours" : "Mo-Fr ${storeLocator.horaireMatinDebut}-${storeLocator.horaireMatinFin};${storeLocator.horaireApmDebut}-${storeLocator.horaireApmFin}",
                  ${logo !== '' ? `"logo": ["${logo}"],`:''}
                  "areaServed": {
                   "@type": "GeoCircle",
                   "geoMidpoint": {
                    "@type": "GeoCoordinates",
                    "latitude": ${agency.latitude},
                    "longitude": ${agency.longitude}
                   },
                   "geoRadius": ${agency.rayonKm}
                  }
                }
              `}
        </script>
      </Helmet>
      <div id="agence">
      <SectionLeftRightComponent image="https://res.cloudinary.com/yzy-paritel/image/upload/v1639999734/store_locator/discussion-image_krjpz1.svg">
        <BreadcrumbComponent path={breadcrumbPath} />
        <div className="block">
          {
            (search || "").trim() !== '' ?           
              <p className={sectionContentSubtitle}>
                Vous êtes situé à « <strong>{search}</strong> »,<br/>
                notre équipe de l’agence de {agency.nom} se déplace jusqu’à vous.
              </p>
              : null
          }
          <h1 className={titre}>
            {
              agency.nom
            }
          </h1>
          {
              isClosed ?
                  <>
                      <div className={hourContainer}>
                          <div className={`${bulletPoint} ${bulletPointClosed}`}/>
                          <div className={`${openingTime} ${closed}`}>
                      {openWhen}{formatTime(openHour)}
                          </div>
                          </div>
                  </>:
                  <>
                      <div className={hourContainer}>
                          <div className={`${bulletPoint}`}/>
                          <div className={openingTime}>
                              {`Ouvert jusqu'a ${formatTime(storeLocator.horaireApmFin)}`}
                          </div>
                      </div>
                  </>
          }
          <p className={address}>
            {agency.adresse}
          </p>
          <p>
            Notre équipe est joignable au téléphone<br />
            du lundi au vendredi de
            {` ${formatTime(storeLocator.horaireMatinDebut)}`} à {formatTime(storeLocator.horaireMatinFin)} et de
            {` ${formatTime(storeLocator.horaireApmDebut)}`} à {formatTime(storeLocator.horaireApmFin)}.
          </p>
          <div className={`${sectionContentActions}`}>
                <CmsButtonComponent btnContext={
                    {
                        style : 'flat',
                        titre: "Prendre rendez-vous",
                        icon: {
                            data: {
                                attributes:{
                                    url:"https://res.cloudinary.com/yzy-paritel/image/upload/c_fill,w_15,h_15/v1640712781/store_locator/icon_calendrier_hi909i.svg",
                                    alternativeText: "appel"
                                }
                            }
                        },
                        action: "popup",
                        ctaCode: "appointment-btn"
                    }
                } style={{marginRight:"1em"}} />
                {!isClosed ?
                <CmsButtonComponent btnContext={
                    {
                        style : 'outline',
                        titre: formatPhone(agency.telephone),
                        icon: {
                            data: {
                                attributes:{
                                    url:"https://res.cloudinary.com/yzy-paritel/image/upload/c_fill,w_10,h_10/v1640712783/store_locator/icon_phone_niuoyl.svg",
                                    alternativeText: "appel"
                                }
                            }
                        },
                        url: 'tel:'+agency.telephone,
                        ctaCode: "call-btn"
                    }
                }/> : null }
          </div>
          <h2 className={titre}>
            Besoin d’un bilan gratuit ?
          </h2>
          <p>Besoin d’une étude personnalisée pour votre projet de télécommunication&nbsp;?
            Prenez RDV avec un de nos experts afin de bénéficier d’un bilan gratuit.
            Nos conseillers se déplacent directement sur votre lieu de travail.
          </p>
          <div className={`${sectionContentActions}`}>
                <CmsButtonComponent btnContext={
                    {
                        style : 'flat',
                        titre: "Mon bilan gratuit",
                        action: "popup",
                        ctaCode: "bilan-btn"
                    }
                }/>
          </div>
        </div>
      </SectionLeftRightComponent>
      </div>
      {
        buildSections(sections, agency, articles, testimonial, [], 0)
      }
    </div>
  )
}

export const query = graphql`
query agenceQuery($id: ID) {
  cms {
    parametre {
        data {
            attributes {
                logo {
                    data {
                        attributes {
                            url
                        }
                    }
                }
            }
        }
    }
    agence(id: $id) {
      data {
        attributes {
          adresse
          nom
          longitude
          latitude
          rayonKm
          codePostal
          geojson
          elfsightCode
          slug
          telephone
          telephoneReel
          departement {
            data {
              attributes {
                region
                slugRegion
                slugDepartement
                nom
              }
            }
          }
          presentation {
            tag
            texte
            titre
          }
          image {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
    pages(filters: {name: {eq: "storelocator/agence"}}) {
      data {
        attributes {
          sections {
            __typename
            ... on CMS_ComponentStoreLocatorSectionSocials {
              id
            }
            ... on CMS_ComponentStoreLocatorSectionAvis {
              id
            }
            ... on CMS_ComponentStoreLocatorSectionTexteImage {
              id
              bouton {
                titre
                style
                url
                action
              }
              image {
                data {
                  attributes {
                    alternativeText
                    url
                  }
                }
              }
              slide {
                tag
                texte
                titre
              }
            }
            ... on CMS_ComponentStoreLocatorSectionTemoignage {
              id
            }
            ... on CMS_ComponentStoreLocatorSectionArticles {
              titre
              nombreArticles
              sousTitre
              typeArticle
              bouton {
                titre
                style
                url
                action
                ctaCode
                icone {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
                Fichier {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
              }
            }
          }
          seoMetaDescription
          seoMetaTitle
        }
      }
    }
    articles(
        filters: {
          or: [
            {agence: {id: {eq: $id}}}
            {agence: {id: {null: true}}}
          ]}
        pagination: {limit: 200}
      ) {
        data {
          attributes {
            publishedAt
            image {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          tag
          titre
          type
          url
          boutonTexte
          resume
        }
      }
    }
    temoignages(filters: {agence: {id: {eq: $id}}}) {
      data {
        attributes {
          temoignage
          temoinLogo {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          temoinNom
          temoinFonction
          raisonSociale
          FichierTemoignage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
    storeLocator {
      data {
        attributes {
          horaireApmDebut
          horaireApmFin
          horaireMatinDebut
          horaireMatinFin
        }
      }
    }
  }
}
`

export default Agence;
